import {
  EmblaCarouselType,
  EmblaOptionsType,
  EmblaPluginType,
} from 'embla-carousel'
import useEmblaCarousel from 'embla-carousel-react'
import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import { cn } from '@/lib/utils'

type Props = {
  children: ReactNode
  options?: EmblaOptionsType
  plugins?: EmblaPluginType[]
  count: number
  hideControls?: boolean
  viewportClassName?: string
  controlClassName?: string
  onSelectSlide?: (slide: number, prevSlide: number) => void
}

const DynamicDotButton: React.FC<{
  onClick: () => void
  className?: string
  index: number
  selectedIndex: number
  totalSlides: number
}> = ({ onClick, className, index, selectedIndex, totalSlides }) => {
  const bulletSize = 12

  // Calculate absolute position relative to active
  const position = index - selectedIndex

  // Determine visibility based on active index position
  let shouldShow = false

  if (selectedIndex === 0) {
    // First active - show first 3 dots
    shouldShow = index >= 0 && index <= 2
  } else if (selectedIndex === totalSlides - 1) {
    // Last active - show last 3 dots
    shouldShow = index >= totalSlides - 3 && index <= totalSlides - 1
  } else if (selectedIndex === 1) {
    // Second - show first 4 dots
    shouldShow = index >= 0 && index <= 3
  } else if (selectedIndex === totalSlides - 2) {
    // Second-last - show last 4 dots
    shouldShow = index >= totalSlides - 4 && index <= totalSlides - 1
  } else {
    // Middle positions - show 5 dots
    shouldShow = Math.abs(position) <= 2
  }

  if (!shouldShow) {
    return null
  }

  // Calculate scale based on position relative to active
  let scale = 0.5
  if (position === 0) scale = 1
  else if (Math.abs(position) === 1) scale = 0.75
  else if (Math.abs(position) === 2) scale = 0.5

  const style = {
    position: 'absolute' as const,
    left: '50%',
    transform: `translateX(calc(-50% + ${position * bulletSize}px))`,
    transition: 'transform 300ms ease',
    '--dot-scale': scale,
  } as React.CSSProperties

  return (
    <button
      className={cn('cursor-pointer', className)}
      type="button"
      onClick={onClick}
      style={style}
    />
  )
}

export const BlockCarousel = forwardRef<EmblaCarouselType | null, Props>(
  (
    {
      children,
      options,
      hideControls,
      count,
      onSelectSlide,
      viewportClassName,
      controlClassName,
      plugins,
    },
    ref,
  ) => {
    const [emblaRef, emblaApi] = useEmblaCarousel(options, plugins)
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

    const onInit = useCallback((embla: EmblaCarouselType) => {
      setScrollSnaps(embla.scrollSnapList())
    }, [])

    const onSelect = useCallback((embla: EmblaCarouselType) => {
      setSelectedIndex(embla.selectedScrollSnap())
    }, [])

    const scrollTo = useCallback(
      (index: number) => emblaApi && emblaApi.scrollTo(index),
      [emblaApi],
    )

    const onSelectSlideCallback = useCallback(
      (embla: EmblaCarouselType) => {
        const curSlide = embla.selectedScrollSnap()
        const prevSlide = embla.previousScrollSnap()
        if (curSlide !== selectedIndex) {
          setSelectedIndex(curSlide)
          onSelectSlide && onSelectSlide(curSlide, prevSlide)
        }
      },
      [onSelectSlide, selectedIndex],
    )

    const onSlidesChangedCallback = useCallback((embla: EmblaCarouselType) => {
      const selectedSnap = embla.selectedScrollSnap()
      embla.reInit()
      if (selectedSnap === 0) {
        embla.scrollTo(1, true)
      }
    }, [])

    useEffect(() => {
      if (!emblaApi) return

      onInit(emblaApi)
      onSelect(emblaApi)
      emblaApi.on('select', onSelect)
      emblaApi.on('reInit', onInit)
      emblaApi.on('settle', onSelectSlideCallback)
      emblaApi.on('slidesChanged', onSlidesChangedCallback)

      return () => {
        emblaApi.off('select', onSelect)
        emblaApi.off('reInit', onInit)
        emblaApi.off('settle', onSelectSlideCallback)
        emblaApi.off('slidesChanged', onSlidesChangedCallback)
      }
    }, [
      emblaApi,
      onInit,
      onSelect,
      onSelectSlideCallback,
      onSlidesChangedCallback,
    ])

    useImperativeHandle(ref, () => emblaApi!, [emblaApi])

    useEffect(() => {
      setSelectedIndex(options?.startIndex || 0)
    }, [options?.startIndex])

    if (count <= 1) {
      return (
        <div className={cn('size-full! overflow-hidden', viewportClassName)}>
          {children}
        </div>
      )
    }

    return (
      <section className="embla">
        <div
          className={cn('embla__viewport', viewportClassName)}
          ref={emblaRef}
        >
          <div className="embla__container">{children}</div>
        </div>
        {!hideControls && (
          <div className={cn('embla__controls', controlClassName)}>
            <div
              className="embla__dots"
              style={{
                position: 'relative',
                height: '16px', // Match dot height from CSS
              }}
            >
              {scrollSnaps.map((_, index) => (
                <DynamicDotButton
                  key={index}
                  onClick={() => scrollTo(index)}
                  className={cn('embla__dot', {
                    'embla__dot--selected': index === selectedIndex,
                  })}
                  index={index}
                  selectedIndex={selectedIndex}
                  totalSlides={scrollSnaps.length}
                />
              ))}
            </div>
          </div>
        )}
      </section>
    )
  },
)

BlockCarousel.displayName = 'BlockCarousel'
